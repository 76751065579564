.slick-slider1 {
  padding-bottom: 80px;
  .slick-list {
    overflow: unset;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .slick-slide {
    opacity: 0.8;
    transition: all 300ms ease;
  }
  .slick-center {
    transform: scale(1.08);
    opacity: 1;
  }
  .slick-next, .slick-prev {
    display: none;
  }
}

.slick-slider1 .slick-slide {
  padding: 0px 45px;
}

.slick-slider1 .slick-track {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.slick-slider1 .slick-track .slick-slide {
  height: auto;
}

.slick-slider1 .slick-track .slick-slide > div {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.slick-slider1 .slick-track .slick-slide .item {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}


.slick-slider {
  .slick-dots {
    li {
      button {
        background: $white;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        @include cubic-transition;
        &:hover {
          background: $primary;
        }
        &:after {
          display: none;
        }
      }
      &.slick-active {
        button {
          background: $primary;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .slick-slider1 .slick-slide {
    padding: 0px 15px;
  }
}
