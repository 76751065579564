/*
*box1
*/
.box1 {
  border: 2px solid $primary;
  padding: 35px;
  border-radius: 5px;
  height: 100%;
  .meta-deta {
    font-weight: 600;
  }
  p {
    font-size: rem-calc(20);
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.ratings {
  margin-bottom: 12px;
  .star {
    display: inline-block;
    width: 18px;
    height: 19px;
    background: url(../images/star.svg) no-repeat;
    background-size: contain;
  }
}
