/*
*button
*/
a, .btn {
  @include cubic-transition;
}

.btn {
  font-size: rem-calc(20);
  border-radius: 2px;
  font-weight: 600;
  padding: 10px 24px;
}

.btn-link-secondary {
  color: $secondary;
}
