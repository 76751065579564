body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: rgba(10,25,36,1);
background: -moz-linear-gradient(left, rgba(10,25,36,1) 0%, rgba(6,27,40,1) 48%, rgba(5,27,42,1) 66%, rgba(3,27,45,1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(10,25,36,1)), color-stop(48%, rgba(6,27,40,1)), color-stop(66%, rgba(5,27,42,1)), color-stop(100%, rgba(3,27,45,1)));
background: -webkit-linear-gradient(left, rgba(10,25,36,1) 0%, rgba(6,27,40,1) 48%, rgba(5,27,42,1) 66%, rgba(3,27,45,1) 100%);
background: -o-linear-gradient(left, rgba(10,25,36,1) 0%, rgba(6,27,40,1) 48%, rgba(5,27,42,1) 66%, rgba(3,27,45,1) 100%);
background: -ms-linear-gradient(left, rgba(10,25,36,1) 0%, rgba(6,27,40,1) 48%, rgba(5,27,42,1) 66%, rgba(3,27,45,1) 100%);
background: linear-gradient(to right, rgba(10,25,36,1) 0%, rgba(6,27,40,1) 48%, rgba(5,27,42,1) 66%, rgba(3,27,45,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a1924', endColorstr='#031b2d', GradientType=1 );

}

.group {
  @include clearfix();
}

img {
  max-width: 100%;
}

figure {
  margin: 0px;
}

h1, h2 {
  margin-bottom: 22px;
}

@media (max-width: 1199px) {
  h1, h2 {
    font-size: rem-calc(30);
  }
}

@media (max-width: 991px) {
  h1, h2 {
    font-size: rem-calc(28);
  }
  .display-1 {
    font-size: rem-calc(36);
  }
}
