#container {
  min-width: 320px;
  overflow: hidden;
}

.sticky {
  #header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background: #041b2c;
    z-index: 100;
  }
  #container {
    padding-top: 100px;
  }
}

@media (max-width: 767px) {
  .sticky {
    #container {
      padding-top: 88px;
    }
  }
}

/*
*header
*/
#header {
  padding: 24px 0px;
  @include cubic-transition;
  min-height: 100px;
  
}

@media (max-width: 767px) {
  #header {
    min-height: 88px;
    .btn-primary {
      display: none;
    }
  }
}

@media (min-width: 992px) {
  #header .container-fluid {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media (min-width: 768px) {
  #header {
    .btn-primary {
      opacity: 0;
      visibility: hidden;
    }
    &.show {
      .btn-primary {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

/*
*banner
*/
#banner {
  padding: 73px 0px 10px;
  p {
    font-size: rem-calc(22);
    line-height: calc(40 / 22);
    color: #a6a6a6;
    span {
      font-weight: 600;
      color: $white;
    }
  }
  .image {
    position: relative;
    &:before {
        content: '';
        border-radius: 50%;
        background-color: rgb(4, 38, 62);
        position: absolute;
        left: -78px;
        top: 19px;
        width: 604px;
        height: 605px;
        z-index: -1;
    }
    &:after {
      content: '';
      border-radius: 50%;
     /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db9e8+0,032136+100&0+0,1+100 */
background: -moz-linear-gradient(top,  rgba(125,185,232,0) 0%, rgba(3,33,54,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(125,185,232,0) 0%,rgba(3,33,54,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(125,185,232,0) 0%,rgba(3,33,54,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#032136',GradientType=0 ); /* IE6-9 */

      box-shadow: 0px 0px 47.12px 28.88px rgba(5, 17, 25, 0.1);
      position: absolute;
      left: -148px;
      top: -49px;
      width: 738px;
      height: 738px;
      z-index: -2;
    }

  }
}

@media (max-width: 991px) {
  #banner {
    .image {
      &:before {
        top: -63px;
      }
      &:after {
        top: -130px;
      }
    }
  }
}


@media (max-width: 767px) {
  #banner {
    padding: 24px 0px;
    .image {
      img {
        max-width: 347px;
        width: 100%;
        max-height: 448px;
      }
    }
    .image {
      &:before {
        height: 388px;
        width: 388px;
        top: 18px;
      }
      &:after {
        top: -9px;
    height: 448px;
    width: 450px;
    left: -122px;
      }
    }
  }
}

@media (min-width: 992px) {
  #banner .container-fluid {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1199px) {
  #banner .container-fluid {
    padding-left: 130px;
    padding-right: 130px;
  }
}


/*
*side-navigation
*/
#side-navigation {
  padding: 0px 30px 0px 62px;
  position: relative;
  .menu {
    list-style-type: none;
    padding-left: 32px;
    li {
      color: #434d54;
      margin-bottom: 11px;
      white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
      a {
        color: inherit;
        &:hover {
          color: $secondary;
        }
      }
    }
    .active {
      color: $secondary;
    }
  }
}

@media (max-width: 1199px) {
  #side-navigation {
    padding: 0 15px 0px 15px;
  }
}

@media (max-width: 767px) {
  .open-menu {
    overflow: hidden;
    #side-navigation {
      right: 0px;
    }
  }
  #side-navigation {

    position: fixed;
    width: calc(100% - 120px);
    right: 0px;
    top: 0px;
    height: 100%;
    background: #032135;
    padding: 98px 15px 75px;
    text-align: right;
    z-index: 1000;
    right: -100%;
    @include cubic-transition;
    .scrollable-content {
      height: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      display: block;
    }
    .menu {
      padding-left: 0px;
      padding-right: 16px;
    }
  }
}

.menu-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(5, 17, 25);
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  @include cubic-transition;
}

.open-menu {
 .menu-overlay {
    opacity: 0.8;
    visibility: visible;
 }
}

/*
*menu btn
*/
.menu-btn {
  padding: 0px;
  border: 0;
  background: none;
  position: absolute;
  right: 15px;
  top: 4px;
  span.line {
      display: block;
      height: 3px;
      width: 28px;
      position: relative;
      margin: 6px 0;
      background: $primary;
      transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }
  span {
      &:first-child {
          transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      }
      &:last-child {
          transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      }
  }
  &:focus {
      outline: none;
  }
}


.close-menu-btn {
  padding: 0px;
  border: 0;
  background: none;
  position: absolute;
  right: 15px;
  top: 28px;
  z-index: 102;
  span.line {
      display: block;
      height: 3px;
      width: 28px;
      position: relative;
      margin: 6px 0;
      background: $primary;
      transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }
  span {
      &:first-child {
          transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      }
      &:last-child {
          transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      }
  }
  span {
    &:first-child {
        left: 0;
        top: 9px;
        transform: rotate(45deg);
    }
    &:last-child {
        left: 0;
        bottom: 9px;
        transform: rotate(-45deg);
    }
    &:nth-child(2) {
        opacity: 0;
    }
}
  &:focus {
      outline: none;
  }
}

/*
*footer
*/
#footer {
  padding: 50px 0px 34px;
  p {
    color: #a6a6a6;
    font-size: rem-calc(16);
  }
  .back-to-top {
    float: right;
  }
  .footer-links {
    padding: 0px;
    list-style: none;
    text-align: right;
    li {
      display: inline-block;
      color: $white;
      font-size: rem-calc(20);
      margin-right: 60px;
      a {
        color: inherit;
        &:hover {
          color: $secondary;
        }

      }
    }
  }
}

@media (max-width: 767px) {
  #footer {
    text-align: center;
    padding: 40px 0px 25px;
    .footer-links {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-right: 30px;
      li {
        margin-right: 10px;
        font-size: rem-calc(14);
      }
    }
  }
  .back-to-top {
    img {
      max-height: 20px;
    }
  }
}

@media (min-width: 992px) {
  #footer .container-fluid {
    padding-left: 40px;
    padding-right: 40px;
  }
}
