/*
*section
*/
.section {
  padding: 100px 0px;
  section {
    padding: 83px 0px;
    .row {
      .col-md-6 {
        padding: 0px 30px;
      }
    }
    .content {
      max-width: 352px;
      width: 100%;
      padding-top: 16px;
      p {
        font-size: rem-calc(32);
      }
    }
    .text-md-right {
      .content {
        display: inline-block;
      }
    }
    .image {
      display: inline-block;
      box-shadow: 0px 0px 47.12px 28.88px rgba(5, 17, 25, 0.3);
    }
  }
}

@media (max-width: 1199px) {
  .section {
    section {
      .content {
        p {
          font-size: rem-calc(18);
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .section {
    section {
      .content {
        h2 {
          margin-bottom: 10px;
        }
      }
      .row {
        .col-md-6 {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .section {
    padding: 70px 0px;
    section {
      padding: 40px 0px;
      .content {
        max-width: 100%;
        margin-bottom: 25px;
      }
      .image {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
}
