.stickit {
	position: fixed;
  top: 0;
  left: 0px;
}



.stickit-end {
	bottom: 0px;
	position: absolute;
	left: 0;
}

.stickem-container .nav-column .stickit {
  padding-left: 62px;
}

@media (max-width: 1199px) {
  .stickem-container .nav-column .stickit {
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .stickem-container .stickem {
    padding-top: 200px;
  }
  .stickem-container .nav-column .stickit {
    padding-top: 100px;
  }
  .stickem-container .nav-column  {
    margin-top: -100px;
  }
}
