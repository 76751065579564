/*
*download-section
*/
.download-section {
  position: relative;
  .container {
    position: relative;
    z-index: 1;
  }
  .download {
    margin-bottom: 10px;
  }
  h5 {
    color: $primary;
    font-weight: 400;
  }
  .content {
    max-width: 480px;
    width: 100%;
    padding-left: 10px;
  }
  footer {
    a {
      display: inline-block;
      margin-right: 10px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .image {
    box-shadow: 0px 0px 47.12px 28.88px rgba(5, 17, 25, 0.3);
  }
  &:after {
      @include absolute-center;
      width: 876px;
      height: 876px;
      content: '';
      border-radius: 50%;
      background: #051a2a;
      box-shadow: 0px 0px 47.12px 28.88px rgba(5, 17, 25, 0.3);
      z-index: -1;
  }
}


@media (max-width: 767px) {
  .download-section {
    footer {
      img {
        max-width: 129px;
      }
    }
  }
}

.counter {
  padding: 0px;
  list-style-type: none;
  li {
    display: inline-block;
    margin-right: 40px;
    text-align: center;
    &:last-child {
      margin-right: 0px;
    }
    .count {
      font-size: rem-calc(74);
      line-height: calc(76 / 74);
      color: $primary;
      font-weight: 300;
    }
    p {
      font-size: rem-calc(22);
      font-weight: 300;
      color: #a6a6a6;
    }
  }
}
@media (max-width: 991px) {
  .counter {
    li {
      .count {
        font-size: rem-calc(36);
        line-height: calc(40 / 36);
      }
    }
  }
}

@media (max-width: 767px) {
  .counter {
    li {
      margin-right: 10px;
      .count {
        font-size: rem-calc(22);
        line-height: calc(26 / 22);
      }
      p {
        font-size: rem-calc(16);
      }
    }
  }
}

.download-icon {
  display: inline-block;
  background: url('../images/download@3x.png') no-repeat;
  width: 13px;
  height: 18px;
  background-size: contain;
}
