/*
*columns
*/

.columns {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  .column {
    padding-left: 15px;
    padding-right: 15px;
  }
  .nav-column {
    width: 407px;
    position: relative;
  }
  .content-column {
    padding-right: 50px;
    width: calc(100vw - 407px);
    -webkit-flex: 1 1 0;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
  }
}

@media (max-width: 1199px) {
  .columns {
    .nav-column {
      width: 300px;
    }
    .content-column {
      width: calc(100vw - 300px);
    }
  }
}

@media (max-width: 767px) {
  .columns {
    .content-column {
      padding-right: 15px;
    }
    .nav-column {
      width: 100%;
    }
    .content-column {
      width: 100%;
    }
  }
}
